import React from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"

const timeout = 750

class Transition extends React.PureComponent {

	constructor(props) {
		super(props)
		this.handleExit = this.handleExit.bind(this)
	}

	handleExit() {
		setTimeout(() => {
			window.scrollTo(0, 0)
			document.body.classList.toggle('fullViewport', this.props.location.pathname === '/')
		}, timeout)
	}

  render() {
		const { children, location } = this.props

    return (
			<>
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={location.pathname}
						timeout={timeout}
						onExit={this.handleExit}
					>
						{children}
					</CSSTransition>
				</SwitchTransition>
			</>
    )
  }
}

export default Transition