import React, { useState, useEffect } from 'react'
import '../styles/style.scss'
import { ContextLoader } from '../context'
import Nav from './Nav'
import Loader from './Loader'
import Transition from './Transition'
import '../helpers/noSSR.js'

const Layout = ({ children, location, data, ...props }) => {

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		document.documentElement.classList.toggle('noScroll', location.pathname === '/')
		document.body.classList.toggle('noScroll', location.pathname === '/')
	}, [location])

	useEffect(() => {
		if (location.pathname === '/') {
			document.body.classList.add('fullViewport')
		}
	}, [])

	const [currentPage, setCurrentPage] = useState(null)
	useEffect(() => {
		if (location.pathname === '/') {
			setCurrentPage('home')
		}
		else if (location.pathname === '/about') {
			setCurrentPage('about')
		}
		else if (location.pathname.includes('/category')) {
			setCurrentPage('category')
		}
		else {
			setCurrentPage('notfound')
		}
	}, [location])

	return (
		<div className={`ActionEtrange ${currentPage}`}>
			<ContextLoader.Provider value={[isLoading, setIsLoading]}>
				<Nav
					data={data.prismicNav.data}
					dataContact={data.prismicContact.data}
					currentPage={currentPage}
				/>
				<Transition location={location}>
					<div className="ActionEtrange__Transition">
						{children}
					</div>
				</Transition>
				<Loader location={location} />
			</ContextLoader.Provider>
		</div>
	)
}

export default Layout