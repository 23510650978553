import React, { useContext, useEffect, useRef, useState } from 'react'
import { ContextLoader } from '../context'

const loadImage = (img) => {
	return new Promise(
		(resolve) => {
			// If image is already loaded
			if (img.complete === true) {
				resolve(img)
			}
			// Add event listeners
			img.addEventListener('load', () => {
				resolve(img)
			});
		},
	);
}

const Loader = ({ location }) => {

	const animDuration = 2000

	const [isLoading, setIsLoading] = useContext(ContextLoader)
	const [currentSrc, setCurrentSrc] = useState(null)
	const [sequenceImgSrcs, setSequenceImgSrcs] = useState([])
	const [isAnimStarted, setIsAnimStarted] = useState(false)
	const [folder, setFolder] = useState(null)
	const imagesEls = useRef([])
	const animFrameCount = useRef(null)

	const initAnimAndLoad = () => {

		setIsAnimStarted(true)

		/**
		*	Load images
		*/
		const imagesLoading = new Promise((resolve) => {

			if (location.pathname === '/') {
				const imagesEl = [...document.querySelectorAll('img')]

				let imagesLoadedCount = 0
				imagesEl.map(imageEl => {
					loadImage(imageEl).then(() => {
						imagesLoadedCount += 1
						if (imagesLoadedCount === imagesEl.length) {
							setTimeout(() => {
								resolve()
							}, 1000)
						}
					})
				})
			}
			else {
				setTimeout(() => {
					resolve()
				}, 250)
			}

		})

		/**
		* Run animation with png sequences
		*/

		const animationRun = new Promise((resolve) => {

			// Launch sequence
			let frameIndex = 0

			const intervalId = window.requestInterval(() => {
				imagesEls.current[frameIndex].classList.remove('isVisible')
				// Update index
				if (frameIndex + 1 > animFrameCount.current - 1) {
					endLoop()
				}
				else {
					frameIndex += 1
				}
				imagesEls.current[frameIndex].classList.add('isVisible')
			}, animDuration / animFrameCount.current)

			const endLoop = () => {
				requestAnimationFrame(() => {
					window.clearRequestInterval(intervalId)
				})
				resolve()
			}
		})

		/**
		*	Promises
		*/
		Promise.all([imagesLoading, animationRun]).then(() => {
			setIsLoading(false)
		})

	}

	useEffect(() => {
		if (window.innerWidth < 800) {
			setFolder('mobile')
			animFrameCount.current = 38
		} else {
			setFolder('desktop')
			animFrameCount.current = 75
		}
	}, [])

	/**
	*	Make sure sequences are loaded before launching the animation
	*/
	const loaderImagesLoadedCount = useRef(0)
	useEffect(() => {
		if (document.readyState === "complete" || document.readyState === "interactive") {
			// requestAnimationFrame(initAnimAndLoad)
			setTimeout(() => {
				initAnimAndLoad()
			}, 500)
		} else {
			document.addEventListener("DOMContentLoaded", () => {
				setTimeout(() => {
					initAnimAndLoad()
				}, 500)
			});
		}
	}, [])

	return (
		<div className={`Loader ${!isLoading ? 'Loader--Ended' : ''}`}>
			<div
				className={`Loader__ImageContainer ${isAnimStarted ? 'isAnimStarted' : ''}`}
			>
				{folder !== null && animFrameCount.current !== null && [...Array(animFrameCount.current)].map((x, i) =>
					<img
						className="Loader__Image"
						src={`/sequences/loader/${folder}/${i.toString().padStart(3, '0')}.png`}
						key={`loader-image-${i}`}
						ref={ref => imagesEls.current[i] = ref}
					/>
				)}
			</div>
		</div>
	)
}

export default Loader