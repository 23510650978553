import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import SplitText from './SplitText'

const Contact = ({ data }) => {

	const [isEmailHovered, setIsEmailHovered] = useState(false)
	const [isEmailCopied, setIsEmailCopied] = useState(false)

	const handleEmailMouseEnter = () => {
		setIsEmailHovered(true)
	}

	const handleEmailMouseLeave = () => {
		setIsEmailHovered(false)
	}

	const handleEmailClick = () => {
		navigator.clipboard.writeText(data.email.text)
			.then(() => {
				setIsEmailCopied(true)
			},
			() => {
				console.log('error')
			}
		)
	}

	return (
		<div className="Contact">
			<div className="Contact__Header">
				<div className="Contact__Text" data-is-on-screen>
					<SplitText hasMask isOnMobile={false}>
						<RichText render={data.text.richText}/>
					</SplitText>
				</div>
				<a
					href={`tel:${data.phone.text}`}
					className="Contact__Phone Contact__LineMask"
					data-is-on-screen
				>
					<div className="Contact__LineContainer">
						{data.phone.text}
					</div>
				</a>
				<div
					className="Contact__Address Contact__LineMask"
					data-is-on-screen
				>
					<div className="Contact__LineContainer">
						{data.address.text}
					</div>
				</div>
			</div>
			<span
				className="Contact__Email Contact__LineMask"
				data-is-on-screen
				onMouseEnter={handleEmailMouseEnter}
				onMouseLeave={handleEmailMouseLeave}
				onClick={handleEmailClick}
			>
				<div className="Contact__LineContainer">
					{data.email.text}
				</div>
			</span>
			<div className={`Contact__CopyContainer ${isEmailHovered ? 'isEmailHovered' : ''} ${isEmailCopied ? 'isEmailCopied' : ''}`}>
				<div className="Contact__CopyRoller">
					<span>Copy to clipboard</span>
					<span>Copied</span>
				</div>
			</div>
			<div className="Contact__Footer">
				<div
					className="Contact__Copyright"
					data-is-on-screen
				>
					<SplitText hasMask isOnMobile={false}>
						<RichText render={data.copyright.richText}/>
					</SplitText>
				</div>
				<ul className="Contact__Links">
					<li className="Contact__Link" data-is-on-screen>
						<a href={data.facebook_link.url} target="_blank">
							<svg className="Contact__LinkIcon" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03369 0.25C4.56994 0.25 3.125 1.55136 3.125 4.51636V7.125H0V10.25H3.125V17.75H6.25V10.25H8.75L9.375 7.125H6.25V5.04492C6.25 3.92805 6.61423 3.375 7.66235 3.375H9.375V0.378174C9.07875 0.338174 8.21557 0.25 7.03369 0.25Z" fill="black"/></svg>
							<svg className="Contact__LinkBorder" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.6324" stroke="#1F1E17" strokeWidth="0.735294"/></svg>
						</a>
					</li>
					<li className="Contact__Link" data-is-on-screen>
						<a href={data.instagram_link.url} target="_blank">
							<svg className="Contact__LinkIcon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.60156 0.75C2.93848 0.75 0.75 2.93555 0.75 5.60156V12.3984C0.75 15.0615 2.93555 17.25 5.60156 17.25H12.3984C15.0615 17.25 17.25 15.0645 17.25 12.3984V5.60156C17.25 2.93848 15.0645 0.75 12.3984 0.75H5.60156ZM5.60156 2.25H12.3984C14.2529 2.25 15.75 3.74707 15.75 5.60156V12.3984C15.75 14.2529 14.2529 15.75 12.3984 15.75H5.60156C3.74707 15.75 2.25 14.2529 2.25 12.3984V5.60156C2.25 3.74707 3.74707 2.25 5.60156 2.25ZM13.4297 3.89062C13.0518 3.89062 12.75 4.19238 12.75 4.57031C12.75 4.94824 13.0518 5.25 13.4297 5.25C13.8076 5.25 14.1094 4.94824 14.1094 4.57031C14.1094 4.19238 13.8076 3.89062 13.4297 3.89062ZM9 4.5C6.52441 4.5 4.5 6.52441 4.5 9C4.5 11.4756 6.52441 13.5 9 13.5C11.4756 13.5 13.5 11.4756 13.5 9C13.5 6.52441 11.4756 4.5 9 4.5ZM9 6C10.667 6 12 7.33301 12 9C12 10.667 10.667 12 9 12C7.33301 12 6 10.667 6 9C6 7.33301 7.33301 6 9 6Z" fill="black"/></svg>
							<svg className="Contact__LinkBorder" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.6324" stroke="#1F1E17" strokeWidth="0.735294"/></svg>
						</a>
					</li>
					<li className="Contact__Link" data-is-on-screen>
						<a href={data.vimeo_link.url} target="_blank">
							<svg className="Contact__LinkIcon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.991 4.5675C17.9108 6.4245 16.6868 8.967 14.3197 12.1935C11.8725 15.564 9.80175 17.25 8.10825 17.25C7.059 17.25 6.171 16.2232 5.44575 14.1705C4.96125 12.2887 4.4775 10.407 3.993 8.52525C3.4545 6.47325 2.877 5.44575 2.259 5.44575C2.124 5.44575 1.3125 6.375 1.3125 6.375L0.28125 5.25C0.28125 5.25 3 0.75 5.34375 0.75C6.84375 0.75 7.55775 1.8645 7.881 4.03725C8.2305 6.381 8.47275 7.83825 8.6085 8.409C9.012 10.3538 9.45225 11.25 9.9375 11.25C10.314 11.25 10.8847 10.6935 11.6392 9.43275C12.2812 8.34375 12.6952 6.65925 12.75 6C12.75 5.15625 12.375 4.5 11.625 4.5C11.3438 4.5 10.7812 4.59375 10.5 5.15625C11.3438 2.90625 12.8438 0.75 15.3765 0.75375C17.226 0.8115 18.0975 2.082 17.991 4.5675Z" fill="black"/></svg>
							<svg className="Contact__LinkBorder" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.6324" stroke="#1F1E17" strokeWidth="0.735294"/></svg>
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Contact

export const query = graphql`
	fragment Contact on PrismicContact {
		data {
      text {
				richText
			}
			phone {
				text
			}
			address {
				text
			}
			email {
				text
			}
			copyright {
				richText
			}
			facebook_link {
				url
			}
			instagram_link {
				url
			}
			vimeo_link {
				url
			}
    }
	}
`