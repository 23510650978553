if (typeof window !== `undefined`) {
	// Set local storage is-touch
	if ("ontouchstart" in document.documentElement) {
		localStorage.setItem('is-touch', 'true');
		document.querySelector('html').classList.add('is-touch');
	} else {
		localStorage.setItem('is-touch', 'false');
	}

	// 100vh bug for phones
	window.onresize = function () {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	window.onresize();

	// requestAnimationFrame() shim by Paul Irish
	// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
	window.requestAnimFrame = (function() {
		return  window.requestAnimationFrame       ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame    ||
			window.oRequestAnimationFrame      ||
			window.msRequestAnimationFrame     ||
			function(/* function */ callback, /* DOMElement */ element){
				window.setTimeout(callback, 1000 / 60);
			};
	})();

	/**
	 * Behaves the same as setInterval except uses requestAnimationFrame() where possible for better performance
	 * @param {function} fn The callback function
	 * @param {int} delay The delay in milliseconds
	 */
	window.requestInterval = function(fn, delay) {
		if( !window.requestAnimationFrame       &&
			!window.webkitRequestAnimationFrame &&
			!(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
			!window.oRequestAnimationFrame      &&
			!window.msRequestAnimationFrame)
				return window.setInterval(fn, delay);

		var start = new Date().getTime(),
			handle = new Object();

		function loop() {
			var current = new Date().getTime(),
				delta = current - start;

			if(delta >= delay) {
				fn.call();
				start = new Date().getTime();
			}

			handle.value = window.requestAnimFrame(loop);
		};

		handle.value = window.requestAnimFrame(loop);
		return handle;
	}

	/**
	 * Behaves the same as clearInterval except uses cancelRequestAnimationFrame() where possible for better performance
	 * @param {int|object} fn The callback function
	 */
	window.clearRequestInterval = function(handle) {
		window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
		window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(handle.value) :
		window.webkitCancelRequestAnimationFrame ? window.webkitCancelRequestAnimationFrame(handle.value) : /* Support for legacy API */
		window.mozCancelRequestAnimationFrame ? window.mozCancelRequestAnimationFrame(handle.value) :
		window.oCancelRequestAnimationFrame	? window.oCancelRequestAnimationFrame(handle.value) :
		window.msCancelRequestAnimationFrame ? window.msCancelRequestAnimationFrame(handle.value) :
		clearInterval(handle);
	};
}